/** @format */

import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./utils/auth/privateRoute";
import PageNotFound from "./pages/notfound/page";
import UnauthorizedPage from "./pages/unauthorized/page";
import AdminMembersPage from "./pages/admin/members/page";
import LoginPage from "./pages/authentication/login/page";
import DashboardPage from "./pages/client/dashboard/page";
import { NotificationProvider } from "./utils/notification/notificationContext";
import { StatusNotification } from "./components/successNotification";
import AdminLoginPage from "./pages/authentication/admin-login/page";
import ReferralsPage from "./pages/client/referrals/page";
import JoinPage from "./pages/join/page";
import AdminFeedbackPage from "./pages/admin/feedback/page";
import CancelSubscription from "./pages/client/dashboard/manage/cancelSubscription";

function DashboardRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                {/* CLIENT ROUTES Addition */}
                <Route
                    path="/"
                    element={
                        <PrivateRoute
                            element={DashboardPage}
                            allowedRoles={["client"]}
                        />
                    }
                />

                <Route
                    path="/referrals"
                    element={
                        <PrivateRoute
                            element={ReferralsPage}
                            allowedRoles={["client"]}
                        />
                    }
                />

                <Route
                    path="/cancel-subscription"
                    element={
                        <PrivateRoute
                            element={CancelSubscription}
                            allowedRoles={["client"]}
                        />
                    }
                />

                <Route path="/r/:referral_code" element={<JoinPage />} />

                {/* ADMIN ROUTES  */}
                <Route
                    path="/admin/members"
                    element={
                        <PrivateRoute
                            element={AdminMembersPage}
                            allowedRoles={["admin"]}
                        />
                    }
                />

                <Route
                    path="/admin/feedback"
                    element={
                        <PrivateRoute
                            element={AdminFeedbackPage}
                            allowedRoles={["admin"]}
                        />
                    }
                />

                <Route path="/login" element={<LoginPage />} />
                <Route path="/admin/login" element={<AdminLoginPage />} />

                {/* ERROR ROUTES  */}
                <Route path="*" element={<PageNotFound />} />
                <Route path="/unauthorized" element={<UnauthorizedPage />} />
            </Routes>
        </BrowserRouter>
    );
}

function App() {
    return (
        <NotificationProvider>
            <DashboardRoutes />
            <StatusNotification />
        </NotificationProvider>
    );
}

export default App;
