/** @format */

import { useState } from "react";
import { InputField } from "../../../../components/inputField";
import NavBar from "../../../../components/navBar";
import { useNavigate } from "react-router-dom";
import LoadingWheel from "../../../../components/loadingWheel";
import { acceptDiscount, cancelSubscription } from "../api/accountApi";
import { useNotification } from "../../../../utils/notification/notificationContext";

const satisfiedReasons = [
    { id: "very_satisfied", title: "Very Satisfied" },
    { id: "satisfied", title: "Satisfied" },
    { id: "neutral", title: "Neutral" },
    { id: "unsatisfied", title: "Unsatisfied" },
    { id: "very_unsatisfied", title: "Very Unsatisfied" },
];

const cancelReasons = [
    { id: "too_expensive", title: "Too expensive" },
    {
        id: "not_used_enough",
        title: "Didn't use the service enough",
    },
    { id: "better_alternative", title: "Found a better alternative" },
    { id: "not_expectations", title: "Service didn't meet expectations" },
    { id: "issues", title: "Technical issues" },
    { id: "customer_service", title: "Poor customer service" },
    { id: "other", title: "Other" },
];

function CancelSubscription() {
    const { showNotification } = useNotification();

    const [satisfactionRating, setSatisfactionRating] = useState("");
    const [satisfactionRatingError, setSatisfactionRatingError] =
        useState(false);
    const [favoriteAspect, setFavoriteAspect] = useState("");
    const [favoriteAspectError, setFavoriteAspectError] = useState(false);
    const [leastFavorite, setLeastFavorite] = useState("");
    const [leastFavoriteError, setLeastFavoriteError] = useState(false);
    const [cancellationReason, setCancellationReason] = useState("");
    const [cancellationReasonError, setCancellationReasonError] =
        useState(false);

    const navigate = useNavigate();

    const [cancelLoading, setCancelLoading] = useState(false);

    const handleCancel = async () => {
        if (satisfactionRating === "") {
            setSatisfactionRatingError(true);
            showNotification("Fill out all fields", "", "error");
            return;
        }
        if (favoriteAspect === "") {
            setFavoriteAspectError(true);
            showNotification("Fill out all fields", "", "error");
            return;
        }
        if (leastFavorite === "") {
            setLeastFavoriteError(true);
            showNotification("Fill out all fields", "", "error");
            return;
        }
        if (cancellationReason === "") {
            setCancellationReasonError(true);
            showNotification("Fill out all fields", "", "error");
            return;
        }

        setCancelLoading(true);
        const data = await cancelSubscription(
            satisfactionRating,
            favoriteAspect,
            leastFavorite,
            cancellationReason
        );
        if (data.status === "success") {
            showNotification("Cancelled subscription", "", "success");
            navigate("/");
        } else {
            showNotification(
                "Failed to cancel subscription",
                "Try again, or contact support.",
                "error"
            );
        }
        setCancelLoading(false);
    };

    const [discountLoading, setDiscountLoading] = useState(false);
    const handleAcceptDiscount = async () => {
        setDiscountLoading(true);
        const data = await acceptDiscount();
        if (data.status === "success") {
            navigate("/");
            showNotification(
                "25% discount claimed to keep your membership.",
                "",
                "success"
            );
        } else {
            showNotification(
                "Failed to accept discount",
                data.message,
                "error"
            );
        }
        setDiscountLoading(false);
    };

    return (
        <div className="flex flex-col ">
            <NavBar />
            <div className="flex flex-col mx-4 sm:mx-16 md:mx-32 lg:mx-44 xl:mx-80 pt-6">
                <h1 className="font-semibold text-2xl text-left items-start pt-5">
                    Cancel subscription
                </h1>
                <div className="pt-4"></div>
                <div className="flex flex-col space-y-6">
                    <div
                        className={`bg-gray-100 rounded-md w-full h-full border ${
                            satisfactionRatingError
                                ? "border-red-500"
                                : "border-transparent"
                        }`}
                    >
                        <div className="px-6 pt-4 pb-6 space-y-4">
                            <h1 className="text-lg font-medium">
                                How satisfied were you with our service overall?
                                <span className="text-red-500">*</span>
                            </h1>
                            <div className="mt-6 space-y-6">
                                {satisfiedReasons.map((satisfiedReason) => (
                                    <div
                                        key={satisfiedReason.id}
                                        className="flex items-center"
                                    >
                                        <input
                                            onClick={() =>
                                                setSatisfactionRating(
                                                    satisfiedReason.title
                                                )
                                            }
                                            id={satisfiedReason.id}
                                            name="satisfied-reason"
                                            type="radio"
                                            className="h-4 w-4 border-gray-300 text-accent focus:ring-0"
                                        />
                                        <label
                                            htmlFor={satisfiedReason.id}
                                            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            {satisfiedReason.title}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`bg-gray-100 rounded-md w-full h-full border ${
                            favoriteAspectError
                                ? "border-red-500"
                                : "border-transparent"
                        }`}
                    >
                        <div className="px-6 pt-4 pb-6 space-y-4">
                            <h1 className="text-lg font-medium">
                                What did you like most about our service?
                                <span className="text-red-500">*</span>
                            </h1>
                            <InputField
                                label=""
                                value={favoriteAspect}
                                onChange={(e) =>
                                    setFavoriteAspect(e.target.value)
                                }
                                placeholder="Your answer"
                            />
                        </div>
                    </div>
                    <div
                        className={`bg-gray-100 rounded-md w-full h-full border ${
                            leastFavoriteError
                                ? "border-red-500"
                                : "border-transparent"
                        }`}
                    >
                        <div className="px-6 pt-4 pb-6 space-y-4">
                            <h1 className="text-lg font-medium">
                                What did you like least about our service?
                                <span className="text-red-500">*</span>
                            </h1>
                            <InputField
                                label=""
                                value={leastFavorite}
                                onChange={(e) =>
                                    setLeastFavorite(e.target.value)
                                }
                                placeholder="Your answer"
                            />
                        </div>
                    </div>
                    <div
                        className={`bg-gray-100 rounded-md w-full h-full border ${
                            cancellationReasonError
                                ? "border-red-500"
                                : "border-transparent"
                        }`}
                    >
                        <div className="px-6 pt-4 pb-6 space-y-4">
                            <h1 className="text-lg font-medium">
                                What is the primary reason for canceling your
                                subscription?
                                <span className="text-red-500">*</span>
                            </h1>
                            <div className="mt-6 space-y-6">
                                {cancelReasons.map((cancelReason) => (
                                    <div
                                        key={cancelReason.title}
                                        className="flex items-center"
                                    >
                                        <input
                                            onClick={() =>
                                                setCancellationReason(
                                                    cancelReason.title
                                                )
                                            }
                                            id={cancelReason.title}
                                            name="cancel-reason"
                                            type="radio"
                                            className="h-4 w-4 border-gray-300 text-accent focus:ring-0"
                                        />
                                        <label
                                            htmlFor={cancelReason.title}
                                            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            {cancelReason.title}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between pb-20">
                        <button
                            onClick={() => navigate("/")}
                            className="hover:border-gray-300 font-medium text-left px-3 py-1.5 rounded-md items-start bg-lightGray border border-grey-200"
                        >
                            Go back
                        </button>
                        <div className="space-x-4">
                            <button
                                onClick={() => handleAcceptDiscount()}
                                className="hover:border-gray-300 font-medium text-left px-3 py-1.5 rounded-md items-start bg-lightGray border border-grey-200"
                            >
                                {discountLoading && (
                                    <span className="pr-2">
                                        <LoadingWheel size="small" />
                                    </span>
                                )}
                                Keep My Plan and Get 25% Off
                            </button>
                            <button
                                disabled={cancelLoading}
                                onClick={() => handleCancel()}
                                className="hover:bg-red-400 font-medium text-left px-3 py-1.5 rounded-md items-start bg-red-500 text-white"
                            >
                                {cancelLoading && (
                                    <span className="pr-2">
                                        <LoadingWheel
                                            color="white"
                                            size="small"
                                        />
                                    </span>
                                )}
                                Cancel plan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CancelSubscription;
