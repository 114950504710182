/** @format */

import { MembershipStatus } from "../models/member";

function StatusTags({ status }: { status: MembershipStatus }) {
    if (status == "active") {
        return (
            <span className="text-green-600 font-semibold bg-green-100 py-1.5 px-2 rounded-md border border-transparent ">
                Active
            </span>
        );
    } else if (status === "past_due") {
        return (
            <span className="text-yellow-600 font-semibold bg-yellow-100 py-1.5 px-2 rounded-md border border-transparent ">
                Past due
            </span>
        );
    } else if (status === "upcoming_cancellation") {
        return (
            <span className="text-yellow-600 font-semibold bg-yellow-100 py-1.5 px-2 rounded-md border border-transparent ">
                Upcoming cancellation
            </span>
        );
    } else {
        return (
            <span className="text-red-600 font-semibold bg-red-100 py-1.5 px-2 rounded-md border border-transparent">
                Cancelled
            </span>
        );
    }

    return <div></div>;
}

export function PaidStatusTags({ paid }: { paid: boolean }) {
    if (paid) {
        return (
            <span className="text-green-600 font-semibold bg-green-100 py-1.5 px-2 rounded-md border border-transparent ">
                Paid
            </span>
        );
    } else {
        return (
            <span className="text-yellow-600 font-semibold bg-yellow-100 py-1.5 px-2 rounded-md border border-transparent ">
                Unpaid
            </span>
        );
    }
}

export default StatusTags;
