

export function formatMoney(amount: number | undefined) {
    if (amount) {
        return `$${(amount / 100).toFixed(2)}`
    }
    if (amount === 0) {
        return `$${amount}`
    }
    return null
}