/** @format */

import { useSearchParams } from "react-router-dom";
import {
    calculateOffset,
    getOffset,
} from "../../../utils/pagination/calculateOffset";
import { useEffect, useState } from "react";
import { Member } from "../../../models/member";
import { Results } from "../../../models/results";
import StatusTags from "../../../components/statusTags";
import { capitalizeFirstLetter } from "../../../utils/strings/capitalize";
import { Feedback } from "../../../models/feedback";

interface FeedbackTableProps {
    feedbacks: Feedback[];
    results: Results | null;
}

function FeedbackTable({ feedbacks, results }: FeedbackTableProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div>
            <div className="overflow-x-scroll scrollbar">
                <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                    <thead>
                        <tr className="text-left text-sm">
                            <th className="py-2 min-w-32">Email</th>
                            <th className="py-2 min-w-32">Username</th>
                            <th className="py-2 min-w-32">
                                Satisfaction Rating
                            </th>
                            <th className="py-2 min-w-32 pl-4">
                                Favorite Aspect
                            </th>
                            <th className="py-2 min-w-32 pl-4">
                                Least Favorite
                            </th>
                            <th className="py-2 min-w-32 whitespace-nowrap pl-4">
                                Cancellation Reason
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {feedbacks &&
                            feedbacks.map((feedback, index) => (
                                <tr
                                    key={index}
                                    className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                >
                                    <td className="py-2.5">
                                        {feedback.email
                                            ? feedback.email
                                            : "None"}
                                    </td>
                                    <td className="py-2.5">
                                        {feedback.username
                                            ? feedback.username
                                            : "None"}
                                    </td>
                                    <td className="py-2.5">
                                        {feedback.satisfaction_rating}
                                    </td>
                                    <td className="py-2.5 pl-4">
                                        {feedback.favorite_aspect}
                                    </td>
                                    <td className="py-2.5 pl-4">
                                        {feedback.least_favorite}
                                    </td>
                                    <td className="py-2.5 pl-4">
                                        {feedback.cancellation_reason}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            {results ? (
                <nav
                    aria-label="Pagination"
                    className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                >
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing{" "}
                            <span className="font-medium">
                                {results.showing_from}
                            </span>{" "}
                            to{" "}
                            <span className="font-medium">
                                {results.showing_to}
                            </span>{" "}
                            of{" "}
                            <span className="font-medium">
                                {results.total_results}
                            </span>{" "}
                            results
                        </p>
                    </div>
                    <div className="flex flex-1 justify-between sm:justify-end">
                        <a
                            href={`/admin/feedback?offset=${calculateOffset(
                                searchParams,
                                "back"
                            )}`}
                            className={`${
                                getOffset(searchParams) === 0
                                    ? "pointer-events-none text-gray-400 ring-gray-200"
                                    : "text-gray-900 ring-gray-300"
                            } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                        >
                            Previous
                        </a>
                        <a
                            href={`/admin/feedback?offset=${calculateOffset(
                                searchParams,
                                "forward"
                            )}`}
                            className={`${
                                results.showing_to >= results.total_results
                                    ? "pointer-events-none text-gray-400 ring-gray-200"
                                    : "text-gray-900 ring-gray-300"
                            } relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                        >
                            Next
                        </a>
                    </div>
                </nav>
            ) : null}
        </div>
    );
}

export default FeedbackTable;
