import Cookies from "js-cookie";

export const fetchReferrals = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/referrals`, 
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json();
        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

export const fetchOnboardingUrl = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/referrals/onboarding`, 
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json();
        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

export const transferFunds = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/referrals/transfer`, 
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json();
        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}