/** @format */

import { useState, useRef, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { searchMembers } from "../pages/admin/members/api/searchApi";
import { Member } from "../models/member";

const commandsData = [
    { name: "Botsack", email: "botsack@gmail.com" },
    { name: "Andre", email: "andre@gmail.com" },
    { name: "Yo", email: "yo@yo.com" },
];

interface HeaderSearchProps {
    setMembers: React.Dispatch<React.SetStateAction<Member[]>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
}

export default function HeaderSearch({
    setMembers,
    setLoading,
    handleRefetch,
}: HeaderSearchProps) {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const inputRef = useRef<HTMLInputElement>(null);

    const commands = commandsData;

    const filteredCommands = commands.filter((command) =>
        command.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleFilterClick = (filter: string) => {
        setSearchTerm(filter);
        if (inputRef.current) {
            inputRef.current.focus(); // Focus the input element
        }
    };

    useEffect(() => {
        searchMembers(searchTerm, setMembers, setLoading, handleRefetch);

        return () => searchMembers.cancel();
    }, [searchTerm]);

    return (
        <div className="relative w-full sm:w-96">
            <div className="group">
                <div className="group flex flex-row items-center space-x-3 px-2 h-8 rounded-md hover:bg-gray-100 focus-within:bg-gray-100 w-full">
                    <FaSearch className="text-gray-400 w-2.5 h-2.5 ml-1" />
                    <input
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="outline-none px-0 py-0 text-xs bg-transparent h-8 w-full border-transparent focus:border-transparent focus:ring-0"
                        ref={inputRef} // Attach the ref to the input
                    />
                </div>
                {/* <div className="max-h-64 overflow-y-auto mt-1 absolute py-1 w-full bg-white border shadow-md rounded-md z-50 text-xs group-focus-within:block hidden">
                    {searchTerm.length > 0 ? (
                        <div>
                            <ul>
                                <li className="px-4 py-2 text-gray-500">
                                    Name:
                                </li>
                            </ul>
                            <ul>
                                {filteredCommands.length > 0 ? (
                                    filteredCommands.map((command, index) => (
                                        <li
                                            key={index}
                                            className="px-4 py-2 hover:bg-gray-100 text-gray-700 hover:text-gray-900 cursor-pointer"
                                        >
                                            {command.name}
                                        </li>
                                    ))
                                ) : (
                                    <li className="px-4 py-2 text-gray-500">
                                        No results
                                    </li>
                                )}
                            </ul>

                            <div className="h-[1px] bg-gray-200 mx-4 my-1"></div>

                            <ul>
                                <li className="px-4 py-2 text-gray-500">
                                    Email:
                                </li>
                            </ul>
                            <ul>
                                {filteredCommands.length > 0 ? (
                                    filteredCommands.map((command, index) => (
                                        <li
                                            key={index}
                                            className="px-4 py-2 hover:bg-gray-100 text-gray-700 hover:text-gray-900 cursor-pointer"
                                        >
                                            {command.email}
                                        </li>
                                    ))
                                ) : (
                                    <li className="px-4 py-2 text-gray-500">
                                        No results
                                    </li>
                                )}
                            </ul>
                        </div>
                    ) : (
                        <div>
                            <div className="px-4 py-2 text-gray-700">
                                Search Filters
                            </div>

                            <button
                                className="px-4 py-2 text-gray-500 cursor-pointer hover:bg-gray-100 w-full text-left"
                                onClick={() => handleFilterClick("email:")}
                            >
                                email:
                            </button>
                            <button
                                className="px-4 py-2 text-gray-500 cursor-pointer hover:bg-gray-100 w-full text-left"
                                onClick={() => handleFilterClick("booking_id:")}
                            >
                                booking_id:
                            </button>
                        </div>
                    )}
                </div> */}
            </div>
        </div>
    );
}
