/** @format */
/** @format */
import { useState, useEffect } from "react";
import LoadingWheel from "../../../components/loadingWheel";
import { verifyLicenseKey } from "../api/authenticationApi";
import { useLocation, useNavigate } from "react-router-dom";
import { checkAuth } from "../../../utils/auth/verifyAuthToken";
import { FaDiscord } from "react-icons/fa";
import Cookies from "js-cookie";
import { useNotification } from "../../../utils/notification/notificationContext";
import { CancelledBanner } from "../../../components/statusBanners";
import { handleLogout } from "../../../utils/auth/handleLogout";

export default function LoginPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState(false);

    const [licenseKey, setLicenseKey] = useState("");
    const [redirectedFromPayment, setRedirectedFromPayment] = useState(false);

    const [failed, setFailed] = useState(false);

    const verifyAuth = async () => {
        const authData = await checkAuth();
        if (authData.authenticated) {
            if (authData.clientType === "client") {
                navigate("/");
            } else if (authData.clientType === "admin") {
                navigate("/admin/login");
            }
        } else {
            Cookies.remove("jwt");
            showNotification(
                "Invalid authorization token",
                "Login using your license key",
                "error"
            );
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        if (Cookies.get("jwt")) {
            const redirected = queryParams.get("redirected");
            if (!redirected) {
                verifyAuth();
            }
        }
    }, []);

    const [isCancelled, setIsCancelled] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get("jwt_token");
        if (token) {
            Cookies.set("jwt", token, {
                expires: 30,
                secure: true,
            });
            verifyAuth();
        }

        const cancelled = queryParams.get("membership_status");
        if (cancelled === "cancelled") {
            setIsCancelled(true);
        }

        const redirected = queryParams.get("redirected");
        if (redirected === "payment_successful") {
            Cookies.remove("jwt");
            localStorage.removeItem("auth_data");
            setRedirectedFromPayment(true);
        }
    }, []);

    const handleVerifyLicense = async () => {
        setLoading(true);
        const data = await verifyLicenseKey(licenseKey);
        if (data.status === "success") {
            Cookies.set("jwt", data.data.jwt_token, {
                expires: 30,
                secure: true,
            });
            navigate("/");
        } else {
            setFailed(true);
        }
        setLoading(false);
    };

    const handleDiscordLogin = async () => {
        window.location.href = `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/discord/login`;
    };

    return (
        <div className="bg-gray-50 h-screen">
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                {isCancelled ? (
                    <div className="sm:mx-auto sm:w-full sm:max-w-[480px] pb-2">
                        <CancelledBanner />
                    </div>
                ) : null}
                <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <img
                                alt="Your Company"
                                src="/logo.png"
                                className="mx-auto h-auto w-20 rounded-md"
                            />
                            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Sign in to your account
                            </h2>

                            {redirectedFromPayment ? (
                                <p className="text-sm text-gray-500 text-center pt-3 font-medium">
                                    Success! You have been added to the Simple
                                    Sauce Discord group, please check your
                                    discord account for access. Please check
                                    your email (junk and spam too) for your
                                    license key. Please sign in with it below
                                    and then bind your Discord account so you
                                    may get access to the dashboard.
                                </p>
                            ) : null}
                        </div>
                        <div className="space-y-6 mt-10">
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    License key
                                </label>
                                <div className="mt-2">
                                    <input
                                        disabled={loading}
                                        placeholder="xxxx-xxxx-xxxx-xxxx"
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        value={licenseKey}
                                        onChange={(e) =>
                                            setLicenseKey(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            {failed ? (
                                <span className="text-red-500 text-sm">
                                    License key doesn't exist.
                                </span>
                            ) : (
                                <></>
                            )}

                            <div>
                                <button
                                    disabled={licenseKey.length < 1 || loading}
                                    onClick={() => handleVerifyLicense()}
                                    type="submit"
                                    className={`${
                                        licenseKey.length >= 1 && !loading
                                            ? "bg-accent hover:bg-accenthighlight cursor-pointer transition"
                                            : licenseKey.length >= 1 && loading
                                            ? "bg-accent cursor-default transition"
                                            : "bg-accentdim cursor-default transition"
                                    } items-center flex w-full justify-center rounded-md px-3 h-9 text-sm font-semibold leading-6 text-white shadow-sm `}
                                >
                                    {loading ? (
                                        <LoadingWheel color="white" />
                                    ) : (
                                        <span>Sign in</span>
                                    )}
                                </button>
                            </div>
                            <div className="mt-10">
                                <div className="relative">
                                    <div
                                        aria-hidden="true"
                                        className="absolute inset-0 flex items-center"
                                    >
                                        <div className="w-full border-t border-gray-200" />
                                    </div>
                                    <div className="relative flex justify-center text-sm font-medium leading-6">
                                        <span className="bg-white px-6 text-gray-900">
                                            Or continue with
                                        </span>
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-1 gap-4">
                                    <button
                                        onClick={() => handleDiscordLogin()}
                                        className="flex w-full items-center justify-center gap-3 rounded-md bg-[#7289da] px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:ring-transparent"
                                    >
                                        <FaDiscord className="h-5 w-auto" />
                                        <span className="text-sm font-semibold leading-6">
                                            Discord
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Not a member?{" "}
                        <button
                            onClick={() =>
                                (window.location.href =
                                    "https://buy.stripe.com/8wM29KdKA3HFgVO9AA")
                            }
                            className="font-semibold leading-6 text-accent hover:text-accenthighlight"
                        >
                            Purchase a membership
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
}

//nB55ClzusJaqZEH7CHBOKIU2UHFjp5lp
//1281273360818376807
