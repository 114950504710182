import Cookies from "js-cookie";


export const fetchUpgradePaymentLink = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/account/upgrade/payment-link`, 
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json();
        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}