/** @format */

import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";
import { renewSubscription } from "../pages/client/dashboard/api/accountApi";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../utils/notification/notificationContext";
import { useState } from "react";
import LoadingWheel from "./loadingWheel";

export function PastDueBanner() {
    return (
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 w-full">
            <div className="flex">
                <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-yellow-400"
                    />
                </div>
                <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                        Your payment is past due.
                        <a
                            href="#"
                            className="font-medium text-yellow-700 underline hover:text-yellow-600"
                        >
                            Please update your membership details before
                            cancellation.
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export function CancelledBanner() {
    return (
        <div className="border-l-4 border-red-400 bg-red-50 p-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-red-400"
                    />
                </div>
                <div className="ml-3">
                    <p className="text-sm text-red-700">
                        Your membership was cancelled.
                        <a
                            href="https://simplesaucegroup.com"
                            className="font-medium text-red-700 underline hover:text-red-600"
                        >
                            Purchase a new license key.
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export function LinkAccountBanner() {
    const handleLinkAccount = () => {
        window.location.href = `${
            process.env.REACT_APP_API_BASE_URL
        }/api/v1/auth/discord/link?token=${Cookies.get("jwt")}`;
    };

    return (
        <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <InformationCircleIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-blue-400"
                    />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">
                        Link your discord account to join the server.
                    </p>
                    <p className="mt-3 text-sm md:ml-6 md:mt-0">
                        <button
                            onClick={() => handleLinkAccount()}
                            className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                        >
                            Link
                            <span aria-hidden="true"> &rarr;</span>
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
}

export function UpcomingCancellationBanner({
    date,
    handleRefetch,
}: {
    date: string;
    handleRefetch: () => void;
}) {
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState(false);

    const handleRenew = async () => {
        setLoading(true);
        const data = await renewSubscription();
        if (data.status === "success") {
            showNotification("Renewed membership", "", "success");
            handleRefetch();
        } else {
            showNotification(
                "Failed to renew membership",
                "Contact support or try again."
            );
        }
        setLoading(false);
    };

    return (
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 w-full">
            <div className="flex flex-row items-center justify-between">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <ExclamationTriangleIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-yellow-400"
                        />
                    </div>
                    <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                            Your subscription is set to cancel on {date}.
                        </p>
                    </div>
                </div>
                <div>
                    <p
                        onClick={() => handleRenew()}
                        className="font-medium text-yellow-700 hover:text-yellow-600 text-sm cursor-pointer"
                    >
                        {loading ? <LoadingWheel /> : <>Renew</>}
                    </p>
                </div>
            </div>
        </div>
    );
}
