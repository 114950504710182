/** @format */

import { UsersIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { LuLayoutDashboard } from "react-icons/lu";
import Settings, { AdminSettings } from "../settings";

const navigation = [
    { name: "Members", href: "/admin/members", icon: UsersIcon },
    { name: "Feedback", href: "/admin/feedback", icon: UsersIcon },
];

export default function AdminSideBar() {
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        const normalizedPath = window.location.pathname.replace(/\/$/, "");
        setCurrentPath(normalizedPath);
    }, []);

    return (
        <div className="hidden lg:block">
            <div className="flex flex-row">
                {/* Apply fixed position */}
                <div className="fixed top-0 w-64 pl-4 pr-2 h-screen pt-6 flex flex-col justify-between">
                    <div>
                        <div className="flex">
                            <img
                                alt="logo"
                                src="/logo.png"
                                className="w-12 h-auto rounded-md"
                            />
                        </div>
                        <div className="pt-10"></div>
                        {navigation.map((item) => (
                            <div className="pt-1" key={item.name}>
                                <a
                                    href={item.href}
                                    className={`${
                                        currentPath === item.href
                                            ? "bg-gray-100 text-accent"
                                            : "hover:bg-gray-100 hover:text-accent group text-gray-700"
                                    } flex flex-row items-center space-x-3 rounded-md p-2`}
                                >
                                    <item.icon
                                        className={`h-6 w-6 shrink-0 group-hover:text-accent ${
                                            currentPath === item.href
                                                ? "text-accent"
                                                : "text-gray-500"
                                        }`}
                                    />
                                    <span className="text-sm font-semibold">
                                        {item.name}
                                    </span>
                                </a>
                            </div>
                        ))}
                    </div>
                    <div className="pb-4">
                        <AdminSettings />
                    </div>
                </div>
                {/* Add margin to content to account for the fixed sidebar */}
                <div className="ml-64">
                    <div className="h-screen fixed w-px bg-gray-300"></div>
                </div>
            </div>
        </div>
    );
}
