/** @format */

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { RxAvatar } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../../components/loadingWheel";
import { useState } from "react";
import { fetchStripePortal, renewSubscription } from "../api/accountApi";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { MembershipStatus } from "../../../../models/member";
import { AccountData } from "../../../../models/account";

export default function EditSubPopup({
    status,
    accountData,
    handleRefetch,
}: {
    status: MembershipStatus;
    accountData: AccountData | null;
    handleRefetch: () => void;
}) {
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState(false);

    const handleManage = async () => {
        setLoading(true);
        const data = await fetchStripePortal();
        if (data.status === "success") {
            window.location.href = data.data.url;
        } else {
            showNotification(
                "Failed to generate portal link",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    const [renewLoading, setRenewLoading] = useState(false);

    const handleRenew = async () => {
        setRenewLoading(true);
        const data = await renewSubscription();
        if (data.status === "success") {
            showNotification("Renewed membership", "", "success");
            handleRefetch();
        } else {
            showNotification(
                "Failed to renew membership",
                "Contact support or try again."
            );
        }
        setRenewLoading(false);
    };

    return (
        <Menu as="div" className="relative text-left">
            {accountData?.plan === "paid" && (
                <div className="pr-4">
                    <MenuButton className="w-full flex-grow">
                        <button className="hover:border-gray-300 font-medium text-left px-3 py-1.5 rounded-md items-start bg-lightGray border border-grey-200">
                            Manage your membership
                        </button>
                    </MenuButton>
                </div>
            )}

            <MenuItems
                transition
                className="absolute top-12 z-10 w-56 mb-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="py-1">
                    <button
                        type="submit"
                        onClick={() => handleManage()}
                        className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-100 text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                        {loading && (
                            <span className="pr-2">
                                <LoadingWheel />
                            </span>
                        )}
                        Edit payment
                    </button>
                    <MenuItem>
                        {status !== "upcoming_cancellation" ? (
                            <button
                                type="submit"
                                onClick={() => navigate("/cancel-subscription")}
                                className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                                Cancel
                            </button>
                        ) : (
                            <button
                                type="submit"
                                onClick={() => handleRenew()}
                                className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                                {renewLoading && (
                                    <span className="pr-2">
                                        <LoadingWheel />
                                    </span>
                                )}
                                Renew
                            </button>
                        )}
                    </MenuItem>
                </div>
            </MenuItems>
        </Menu>
    );
}
