/** @format */

import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { fetchOnboardingUrl } from "../pages/client/referrals/api/referralsApi";
import { useNotification } from "../utils/notification/notificationContext";
import LoadingWheel from "./loadingWheel";

export default function SetupReferralBanner() {
    const { showNotification } = useNotification();
    const [onboardingLoading, setOnboardingLoading] = useState(false);
    const handleOnboarding = async () => {
        setOnboardingLoading(true);
        const data = await fetchOnboardingUrl();
        if (data.status === "success") {
            window.location.href = data.data.url;
        } else {
            showNotification(
                "Failed to generate onboarding url",
                data.message,
                "error"
            );
        }
        setOnboardingLoading(false);
    };

    return (
        <div className="rounded-md bg-gray-50 p-4">
            <div className="flex items-center">
                <div className="flex-shrink-0">
                    <InformationCircleIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-gray-400"
                    />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between items-center">
                    <p className="text-sm text-gray-700">
                        Finish setting up your account to withdraw your
                        referrals.
                    </p>
                    <p className="mt-3 text-sm md:ml-6 md:mt-0">
                        <button
                            onClick={() => handleOnboarding()}
                            className="whitespace-nowrap border border-gray-300 px-2 py-0.5 rounded-md font-medium text-gray-700 hover:text-gray-600 text-sm"
                        >
                            {onboardingLoading && (
                                <span className="pr-2">
                                    <LoadingWheel size="small" color="black" />
                                </span>
                            )}
                            Setup
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
}
