/** @format */

import { useEffect, useState } from "react";
import AdminSideBar from "../../../components/admin/adminSideBar";
import Header from "../../../components/header";
import FeedbackTable from "./feedbackTable";
import { Feedback } from "../../../models/feedback";
import { Results } from "../../../models/results";
import LoadingWheel from "../../../components/loadingWheel";
import HeaderSearch from "../../../components/searchBar";
import { fetchFeedbacks } from "./api/feedbackApi";
import { useSearchParams } from "react-router-dom";
import { useNotification } from "../../../utils/notification/notificationContext";

function AdminFeedbackPage() {
    const [loading, setLoading] = useState(false);
    const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
    const [results, setResults] = useState<Results | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const { showNotification } = useNotification();

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchFeedbacks(searchParams);
            if (data.status === "success") {
                setFeedbacks(data.data.feedbacks);
            } else {
                showNotification("Failed to fetch data", data.message, "error");
            }
        };
        fetchData();
    }, []);

    return (
        <div className="flex flex-row">
            <AdminSideBar />
            <div className="flex flex-col flex-grow px-4 sm:px-10 pt-2 sm:pt-5 overflow-x-scroll">
                <Header />
                {/* <HeaderSearch
                    setMembers={setMembers}
                    setLoading={setLoading}
                    handleRefetch={handleRefetch}
                /> */}
                <div className="pt-5"></div>
                <h1 className="font-bold text-2xl">Feedback</h1>
                <div className="pt-6">
                    {!loading ? (
                        <FeedbackTable
                            feedbacks={feedbacks}
                            results={results}
                        />
                    ) : (
                        <div className="flex flex-col flex-grow justify-center items-center pt-40">
                            <LoadingWheel />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AdminFeedbackPage;
