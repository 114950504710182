import Cookies from 'js-cookie';
import { debounce } from 'lodash';
import { Member } from '../../../../models/member';

export const searchMembers = debounce(async (
    searchText: string,
    setMembers: React.Dispatch<React.SetStateAction<Member[]>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    handleRefetch: () => void
) => {
    if (searchText.trim() === "") {
        handleRefetch()
        return;
    }
    try {
        setLoading(true)
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/members/search?searchText=${searchText}`, 
            {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + Cookies.get("jwt")
                }
            }
        );
        const data = await response.json();
        if (data.status === "success") {
            setMembers(data.data.members)
        }
    } catch (error) {

    } finally {
        setLoading(false)
    }
}, 300);