import Cookies from "js-cookie";
import { MembershipStatus } from "../../../../models/member";


export const fetchMembers = async (
    searchParams: URLSearchParams,
    status: MembershipStatus
) => {

    try {
        const offset = searchParams.get('offset')
        const email = searchParams.get('email')

        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/members?status=${status}${offset ? '&offset=' + encodeURIComponent(offset) : ''}${email !== "" && email ? '&emailFilter=' + encodeURIComponent(email) : ''}`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data

    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
};

export const fetchMembersCount = async () => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/members/count`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data

    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
};