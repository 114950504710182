/** @format */

import { useEffect, useState } from "react";
import AdminSideBar from "../../../components/admin/adminSideBar";
import { fetchMembers, fetchMembersCount } from "./api/membersApi";
import MembersTable from "./membersTable";
import {
    Member,
    MembershipStatus,
    TotalMembersFilters,
} from "../../../models/member";
import { Results } from "../../../models/results";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LoadingWheel from "../../../components/loadingWheel";
import TableFilters, { GenericFilters } from "./filters";
import HeaderSearch from "../../../components/searchBar";
import Header from "../../../components/header";

function AdminMembersPage() {
    const [members, setMembers] = useState<Member[]>([]);
    const [results, setResults] = useState<Results | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const [fullLoading, setFullLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [filtersCount, setFiltersCount] =
        useState<TotalMembersFilters | null>(null);

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const navigate = useNavigate();
    const location = useLocation();

    const getQueryParams = () => {
        const queryParams = new URLSearchParams(location.search);
        return (queryParams.get("filter") as MembershipStatus) || "all";
    };

    const [selectedMemberFilter, setSelectedMemberFilter] =
        useState<MembershipStatus>(getQueryParams());

    useEffect(() => {
        const status = getQueryParams();
        setSelectedMemberFilter(status);
        // Navigate to the correct page if URL is invalid
        if (
            status &&
            [
                "all",
                "paid",
                "free",
                "past_due",
                "cancelled",
                "upcoming_cancellation",
            ].indexOf(status) === -1
        ) {
            navigate(`/admin/members?filter=all`, {
                replace: true,
            });
        }
    }, [location.search, navigate]);

    const handleMemberStatusChange = (newStatus: MembershipStatus) => {
        setSelectedMemberFilter(newStatus);
        navigate(`/admin/members?filter=${newStatus}`);
    };

    const fetchMembersData = async () => {
        setLoading(true);
        const data = await fetchMembers(searchParams, selectedMemberFilter);
        if (data.status === "success") {
            setMembers(data.data.members);
            setResults(data.data.results);
        } else {
        }
        setLoading(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            setFullLoading(true);
            const countData = await fetchMembersCount();

            if (countData.status === "success") {
                setFiltersCount(countData.data);
            }
            setFullLoading(false);
        };

        fetchData();
    }, []);

    useEffect(() => {
        fetchMembersData();
    }, [refetchData, selectedMemberFilter]);

    return (
        <div className="flex flex-row">
            <AdminSideBar />
            <div className="flex flex-col flex-grow px-4 sm:px-10 pt-2 sm:pt-5 overflow-x-scroll">
                <Header />
                <HeaderSearch
                    setMembers={setMembers}
                    setLoading={setLoading}
                    handleRefetch={handleRefetch}
                />
                <div className="pt-5"></div>
                <h1 className="font-bold text-2xl">Members</h1>

                <div className="pt-6"></div>
                {!fullLoading ? (
                    <div className="">
                        <TableFilters
                            filtersCount={filtersCount}
                            selectedMemberFilter={selectedMemberFilter}
                            handleMemberStatusChange={handleMemberStatusChange}
                        />

                        {!loading ? (
                            <MembersTable members={members} results={results} />
                        ) : (
                            <div className="flex flex-col flex-grow justify-center items-center pt-40">
                                <LoadingWheel />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="flex flex-col flex-grow justify-center items-center pt-40">
                        <LoadingWheel />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AdminMembersPage;
