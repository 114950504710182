/** @format */

import { useNavigate } from "react-router-dom";
import Settings from "./settings";
import { useEffect, useState } from "react";

const paths = [
    { label: "Referrals", key: "referrals", path: "/referrals" },
    { label: "Dashboard", key: "dashboard", path: "/" },
];

function NavBar() {
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        setCurrentPath(window.location.pathname);
    }, []);

    return (
        <div className="flex flex-row w-full h-16 items-center justify-end sm:justify-between px-4 sm:px-10">
            <div className="hidden sm:flex flex-row items-center space-x-4">
                <img src="/logo.png" className="h-10 w-10 rounded-md" />
                <span className="font-medium text-md">Simple Sauce Group</span>
            </div>
            <div className="flex flex-row items-center space-x-10">
                {paths.map((item) => (
                    <a
                        key={item.key}
                        className={`font-medium text-md hover:text-accent transition-all ${
                            item.path === currentPath && "text-accent"
                        }`}
                        href={item.path}
                    >
                        {item.label}
                    </a>
                ))}
                <Settings />
            </div>
        </div>
    );
}

export default NavBar;
