import Cookies from "js-cookie"


export const fetchAccountData = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/account`, 
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json();
        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

export const fetchStripePortal = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/account/portal`, 
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json();
        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

export const cancelSubscription = async (
    satisfaction_rating: string,
    favorite_aspect: string,
    least_favorite: string,
    cancellation_reason: string
) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/account/cancel-subscription`, 
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({
                    satisfaction_rating,
                    favorite_aspect,
                    least_favorite,
                    cancellation_reason
                })
            }
        )
        const data = await response.json();
        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

export const renewSubscription = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/account/renew-subscription`, 
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json();
        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

export const acceptDiscount = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/account/accept-subscription-discount`, 
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json();
        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}