/** @format */

import { FilterByEmail } from "../../../components/filterPopups";
import { MembershipStatus, TotalMembersFilters } from "../../../models/member";
import { useRef } from "react";
import { IoAddOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function TableFilters({
    filtersCount,
    selectedMemberFilter,
    handleMemberStatusChange,
}: {
    filtersCount: TotalMembersFilters | null;
    selectedMemberFilter: string;
    handleMemberStatusChange: (newPage: MembershipStatus) => void;
}) {
    const filterOptions = [
        { label: "All", key: "all", value: filtersCount?.all },
        {
            label: "Paid",
            key: "paid",
            value: filtersCount?.paid_active,
        },
        {
            label: "Free",
            key: "free",
            value: filtersCount?.free_active,
        },
        {
            label: "Past due",
            key: "past_due",
            value: filtersCount?.past_due,
        },
        {
            label: "Upcoming cancellations",
            key: "upcoming_cancellation",
            value: filtersCount?.upcoming_cancellation,
        },
        {
            label: "Cancelled",
            key: "cancelled",
            value: filtersCount?.cancelled,
        },
    ];

    return (
        <div className="flex flex-row py-4 space-x-1">
            {filterOptions.map((option) => (
                <button
                    key={option.label}
                    onClick={() =>
                        handleMemberStatusChange(option.key as MembershipStatus)
                    }
                    className={`flex flex-col border py-2 pl-3 rounded-lg w-full ${
                        selectedMemberFilter === option.key
                            ? "border-accent"
                            : "border-gray-300 hover:border-accenthighlight"
                    }`}
                >
                    <p
                        className={`text-xs ${
                            selectedMemberFilter === option.key
                                ? "text-accent font-medium"
                                : "text-gray-500"
                        }`}
                    >
                        {option.label}
                    </p>
                    <p
                        className={`text-sm ${
                            selectedMemberFilter === option.key
                                ? "text-accent font-medium"
                                : "text-gray-700"
                        }  font-semibold`}
                    >
                        {option.value}
                    </p>
                </button>
            ))}
        </div>
    );
}

export function GenericFilters({
    handleRefetch,
}: {
    handleRefetch: () => void;
}) {
    const [searchParams, setSearchParams] = useSearchParams();

    const emailButtonRef = useRef<HTMLButtonElement>(null);

    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const toggleFilter = () => {
        setIsFilterOpen((prevState) => !prevState);
    };

    const setFilter = (key: string, value: string | null) => {
        setSearchParams((prevParams) => {
            // Create a new URLSearchParams object from the previous params
            const newParams = new URLSearchParams(prevParams);

            if (value === null || value === "") {
                handleRefetch();
                newParams.delete(key);
            } else {
                handleRefetch();
                newParams.set(key, value);
            }

            return newParams;
        });
    };

    return (
        <div className="flex flex-row items-center space-x-2">
            <div className="relative">
                <button
                    ref={emailButtonRef}
                    onClick={toggleFilter}
                    className="text-xs flex flex-row items-center border border-dashed border-gray-400 rounded-xl px-2.5 py-0.5 hover:bg-gray-100 hover:border-gray-500"
                >
                    {searchParams.get("email") === "" ||
                    !searchParams.get("email") ? (
                        <IoAddOutline className="h-4 w-4 text-gray-700" />
                    ) : (
                        <IoClose
                            onClick={() => setFilter("email", "")}
                            className="h-4 w-4 text-red-700"
                        />
                    )}
                    <span className="pl-1 font-normal text-gray-700">
                        Email
                        <span className="pl-2 text-accent">
                            {searchParams.get("email")}
                        </span>
                    </span>
                </button>
                <FilterByEmail
                    isOpen={isFilterOpen}
                    onClose={() => setIsFilterOpen(false)}
                    setFilter={setFilter}
                    emailButtonRef={emailButtonRef} // Pass ref to FilterByEmail
                />
            </div>
        </div>
    );
}
