/** @format */

import { useEffect, useState } from "react";
import LoadingWheel from "../../components/loadingWheel";
import { useParams } from "react-router-dom";
import { fetchReferralCheckout } from "./api/joinApi";

function JoinPage() {
    const { referral_code } = useParams();

    const [validCode, setValidCode] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchReferralCheckout(referral_code);
            if (data.status === "success") {
                window.location.href = data.data.url;
            } else {
                setValidCode(false);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="w-screen pt-44 flex justify-center items-center flex-col">
            {validCode ? (
                <LoadingWheel />
            ) : (
                <span className="text-gray-600">
                    Referral code is invalid or does not exist.
                </span>
            )}
        </div>
    );
}

export default JoinPage;
